@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;700&family=Source+Sans+Pro:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.rounded-true {
  --rounded-box: 1rem;
  --rounded-btn: 0.5rem;
  --rounded-badge: 1.9rem;
}

.rounded-false {
  --rounded-box: 0;
  --rounded-btn: 0;
  --rounded-badge: 0;
}
